import React from 'react';
import { Field, ErrorMessage } from "formik";
import TextError from './TextError';
import "../styles/checkbox-group-custom.scss";

export default function DangerousGoodsOptionsCheckboxGroup(props) {
  const { label, name, options, ...rest } = props;

  return (
    <div className="form-control checkbox-group-custom">
      <label className="checkbox-group-custom__label">{label}</label>
      <div className="checkbox-group-custom__checkboxes">
        <Field name={name} {...rest}>
          {({ form, field }) => {
            const handleChange = (optionValue, checked) => {
              let values = field.value || [];

              if (checked) {
                const index = values.indexOf("isNotCarryingDangerousGoods");

                if (optionValue === "isNotCarryingDangerousGoods") {
                  values = [optionValue];
                } else {
                  if (index !== -1) {
                    values = [optionValue];
                  } else {
                    values.push(optionValue);
                  }
                }
              } else {
                const index = values.indexOf(optionValue);
                if (index !== -1) {
                  values.splice(index, 1);
                }
              }

              form.setFieldValue(field.name, values);
            };

            return (
              options.map(option => {
                const isChecked = (field.value || []).includes(option.value);

                return (
                  <div
                    onClick={() => {
                      const checked = !isChecked;
                      handleChange(option.value, checked);
                    }}
                    className={"checkbox" + (isChecked ? " checked" : "")}
                    key={option.key}
                  >
                    <input
                      type="checkbox"
                      id={option.value}
                      {...field}
                      value={option.value}
                      checked={isChecked}
                    />
                    <label>{option.key}</label>
                  </div>
                );
              })
            );
          }}
        </Field>
      </div>

      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
