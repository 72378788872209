import { Field } from "@navozyme/uikit/dist/atoms/Field";
import { DatePickerTimeInput } from "@navozyme/uikit/dist/molecules/DatePicker";
import { Form, Formik, } from "formik";
import { default as React, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import SearchPortsDropdown from "../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown";
import { FormRefContext } from '../../../../global/context/FormRefContext';
import PageHeader from '../../../../layout/components/PageHeader';
import { selectPortCallData, updatePortCallData } from '../../../slices/portCallSlice';
import "../../../styles/last-and-next-port.scss";
import SkipAndNextButtons from './SkipAndNextButtons';


const validationSchema = Yup.object({
  lastPort: Yup.object(),
  nextPort: Yup.object(),
  atdLastPort: Yup.string(),
  etaNextPort: Yup.string(),
})

function LastAndNexPort() {
  const formRef = useRef();
  const {formContextRef} = useContext(FormRefContext);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const portCallData = useSelector(selectPortCallData);

  const initialValues = {
    lastPort: portCallData.data.lastPort,
    nextPort: portCallData.data.nextPort,
    atdLastPort: portCallData.data.atdLastPort?.actualTime ? new Date(portCallData.data.atdLastPort.actualTime) : "",
    etaNextPort: portCallData.data.etaNextPort?.estimatedTime ? new Date(portCallData.data.etaNextPort.estimatedTime) : ""
  };

  const getDataForRequest = values => {
    const data = {
      lastPort: values.lastPort,
      nextPort: values.nextPort,
    }

    if (values.atdLastPort)
      data.atdLastPort = { actualTime: values.atdLastPort.toISOString() }

    if (values.etaNextPort)
      data.etaNextPort = { estimatedTime: values.etaNextPort.toISOString() }

    return data
  }

  const handleSubmit = async (values, _) => {
    dispatch(updatePortCallData({
      uid: params.uid,
      data: getDataForRequest(values)
    }));
  };

  useEffect(() => {
    formContextRef.current = formRef.current; 
  }, [formContextRef, formRef, portCallData]);

  return (
    <div className="last-and-next-port">
      <PageHeader title={"Port call data"} />
      <Formik
        innerRef={formRef}
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        {({values, setValues, errors})=>{
          return (
            <Form className="last-and-next-port-form">
              <div className="last-and-next-port-form__controls">
                <div className="last-and-next-port-form__controls__row">
                  <SearchPortsDropdown
                    label="What was your last port?"
                    name="lastPort"
                    dropdownID="lastPort"
                    placeholder="Last Port" />
                  <Field label="ATD:" className="field_date_picker_lastPort">
                    <DatePickerTimeInput
                        key={initialValues?.atdLastPort + "atdLastPort"}
                        name="atdLastPort"
                        sizeInput="large"
                        maxDate={portCallData?.data?.eta?.estimatedTime || new Date()}
                        showTime={true}
                        defaultValue={
                          initialValues?.atdLastPort
                        }
                        action={({ isoDate }) =>
                            setValues({...values, atdLastPort: new Date(isoDate)})

                        }
                        hasError={errors?.atdLastPort}
                        errorMessage={errors?.atdLastPort}
                    />
                  </Field>
                </div>
                <div className="last-and-next-port-form__controls__row">
                  <SearchPortsDropdown
                    label="And the next port?"
                    name="nextPort"
                    dropdownID="nextPort"
                    placeholder="Next Port" />
                  <Field label="ETA:" className="field_date_picker_lastPort">
                    <DatePickerTimeInput
                        key={initialValues?.etaNextPort + "etaNextPort"}
                        name="etaNextPort"
                        sizeInput="large"
                        minDate={portCallData?.data?.etd?.estimatedTime || new Date()}
                        showTime={true}
                        defaultValue={
                          initialValues?.etaNextPort
                        }
                        action={({ isoDate }) =>
                            setValues({...values, etaNextPort: new Date(isoDate)})

                        }
                        hasError={errors?.etaNextPort}
                        errorMessage={errors?.etaNextPort}
                    />
                  </Field>
                </div>
              </div>
              <SkipAndNextButtons
                next={`/${params.uid}/port-call-data/purpose`}
                back={`/${params.uid}/port-call-data/eta-etd`}
                onClick={()=>navigate(`/${params.uid}/port-call-data/purpose`)}
              />
            </Form>
              )
        }}
      </Formik>

    </div>
  );
}

export default LastAndNexPort;
