import { Field } from "@navozyme/uikit/dist/atoms/Field";
import { DatePickerTimeInput } from "@navozyme/uikit/dist/molecules/DatePicker";
import { format } from 'date-fns';
import { Form, Formik, } from "formik";
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import Input from '../../../../forms/components/Input';
import MultiSelect from '../../../../forms/components/MultiSelect';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import { shipTypeOptions } from '../../../../global/globalTypes';
import { removeNullUndefined } from "../../../../global/utils/cleanEmptyData";
import { countryListAllIsoData } from '../../../../global/utils/countriesList';
import { API_REQUEST_FORMAT_DATE } from '../../../../global/utils/datetimes';
import Page from '../../../../layout/components/Page';
import PageHeader from '../../../../layout/components/PageHeader';
import ProgressBar from '../../../../layout/components/ProgressBar';
import { getCompanies, selectCompanies } from '../../../slices/companySlice';
import { getDangerousGoodsData, getVessel, selectDangerousGoods, selectVesselData, updateDangerousGoodsData, updateVessel } from '../../../slices/portCallSlice';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import "../styles/vessel-data.scss";
import { getShipCompanyOptions } from '../utils/companyUtils';

const validationSchema = Yup.object({
  imo: Yup.number().test('len', 'Must be exactly 7 numbers', val => {
    if (val) {
      return val.toString().length === 7
    }
    return true;
  }),
  mmsi: Yup.number().test('len', 'Must be exactly 9 numbers', val => {
    if (val) {
      return val.toString().length === 9;
    }
    return true;
  }),
  shipType: Yup.string().oneOf(shipTypeOptions, "Invalid ship type"),
  length: Yup.number().min(1, 'Must be greater than 0'),
  breadth: Yup.number().min(1, 'Must be greater than 0'),
  hullConfiguration: Yup.string().required()
});

function VesselData() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const companies = useSelector(selectCompanies);
  let vesselData = useSelector(selectVesselData);
  const dangerousGoods = useSelector(selectDangerousGoods);

  useEffect(() => {
    dispatch(getVessel(params.uid));
    dispatch(getCompanies());
    dispatch(getDangerousGoodsData(params.uid));
  }, [dispatch, params.uid])

  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, vesselData, dangerousGoods]);

  const initialValues = {
    hullConfiguration: "SINGLE_HULL",
    ...vesselData,
    shipType: vesselData.shipType,
    buildDate: vesselData.buildDate ? new Date(vesselData.buildDate) : null,
    shipOwner: vesselData.shipOwner ? vesselData.shipOwner.name : "",
    isTransportingHeavyOils: dangerousGoods.dangerousGoodsData ? dangerousGoods.dangerousGoodsData.isTransportingHeavyOils : null
  };

  const handleSubmit = async (values, onSubmitProps) => {
    let finalValues = { ...values };
    finalValues.shipOwner = companies.find(company => company.name === values.shipOwner);

    if (values.buildDate) {
      finalValues.buildDate = format(new Date(values.buildDate), API_REQUEST_FORMAT_DATE);
    }
    finalValues.doubleHull = finalValues?.hullConfiguration === "DOUBLE_HULL"

    if (values.isTransportingHeavyOils !== undefined) {
      dispatch(updateDangerousGoodsData({
        uid: params.uid,
        dangerousGoodsData: {
          isTransportingHeavyOils: values.isTransportingHeavyOils
        }
      }));
    }

    dispatch(updateVessel({
      uid: params.uid,
      data: removeNullUndefined(finalValues)
    }))
  };

  const hullConfigurationOptions = [
    { key: "Single hull", value: "SINGLE_HULL" },
    { key: "Single hull SBT", value: "SINGLE_HULL_BST" },
    { key: "Double hull", value: "DOUBLE_HULL" },
  ];

  const carryingOptions = booleanOptionsForRadioGroup();

  const validationNavigate = async (values) => {
    try {
      const res = await validationSchema.validate(values, { abortEarly: false });;
      navigate(`/${params.uid}/inspections`)
    } catch (error) {

    }
  }

  return (
    <Page>
      <div className="vessel-data">
        <ProgressBar percentage={"100"} pageNumber={1} totalPages={"1"} />
        <div className="vessel-data__header">
          <PageHeader
            title={"Vessel data"}
            subtitle={`Please, verify if the data of the vessel is correct.`} />
        </div>
        <Formik
          onSubmit={handleSubmit}
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}>
          {({ values, errors, setValues, isValid }) => (
            <Form className="vessel-data-form">
              <div className="vessel-data-form__controls">
                <div className="vessel-data-form__first-row">
                  <div className="vessel-data-form__first-column">
                    <Input name="imo" type="number" placeholder="IMO Number" label="IMO Number" onBlur={() => formRef.current.submitForm()} />
                    <Input name="shipName" placeholder="Ship name" label="Ship name" onBlur={() => formRef.current.submitForm()} />
                    <Input name="shipType" placeholder="Ship type" label="Ship type" onBlur={() => formRef.current.submitForm()} />
                    <Input name="grossTonnage" type="number" placeholder="Gross tonnage (m)" label="Gross tonnage (m)" onBlur={() => formRef.current.submitForm()} />
                    <Input name="deadweight" type="number" placeholder="Deadweight tonnage (m)" label="Deadweight tonnage (m)" onBlur={() => formRef.current.submitForm()} />
                    <Input name="length" type="number" placeholder="Length" label="Length" onBlur={() => formRef.current.submitForm()} />
                  </div>
                  <div className="vessel-data-form__second-column">
                    <MultiSelect
                      className={"multiselect"}
                      name="flag"
                      options={countryListAllIsoData}
                      isMulti={false}
                      label="Flag state"
                      placeholder="Flag state"
                      onBlur={() => formRef.current.submitForm()}
                    />
                    <Input name="callsign" placeholder="Call sign" label="Call sign" onBlur={() => formRef.current.submitForm()} />
                    <Input name="mmsi" type="number" placeholder="MMSI number" label="MMSI number" onBlur={() => formRef.current.submitForm()} />
                    <Field label="Date of build" className="field_date_picker">
                      <DatePickerTimeInput
                        key={initialValues?.buildDate + "buildDate"}
                        name="buildDate"
                        sizeInput="small"
                        maxDate={new Date()}
                        showTime={false}
                        placeholder="Date of build"
                        defaultValue={
                          initialValues?.buildDate
                        }
                        action={({ isoDate }) => {
                          const finalBuildDate = (!isoDate || isoDate === null || isoDate === '') ? null : new Date(isoDate);
                          setValues({ ...values, buildDate: finalBuildDate });
                          formRef.current.submitForm()
                        }}
                        hasError={errors?.buildDate}
                        errorMessage={errors?.buildDate}
                      />
                    </Field>
                    <MultiSelect
                      className={"multiselect"}
                      name="shipOwner"
                      options={getShipCompanyOptions(companies, vesselData.shipOwner?.name)}
                      isMulti={false}
                      label="Ship company name"
                      placeholder="Ship company name"
                      onBlur={() => formRef.current.submitForm()}
                    />
                    <Input name="breadth" type="number" placeholder="Breadth (m)" label="Breadth (m)" onBlur={() => formRef.current.submitForm()} />
                  </div>
                </div>
                <div className="vessel-data-form__second-row">
                  <RadioButtonsCustom name="hullConfiguration" label="Please, indicate the configuration of the hull." options={hullConfigurationOptions} onBlur={() => formRef.current.submitForm()} />
                  <RadioButtonsCustom name="isTransportingHeavyOils" label="Are you carrying commercially heavy fuel oil, tar, bitumen or heavy crude oil?" options={carryingOptions} onBlur={() => formRef.current.submitForm()} />
                </div>
              </div>
              <SkipAndNextButtons
                next={`/${params.uid}/inspections`}
                back={`/${params.uid}/port-call-data/questions`}

                onClick={() => validationNavigate(values)}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Page>
  );
}

export default VesselData;

