import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import '../styles/checkbox-group-bunkering.scss';

export default function BunkeringCheckboxGroup(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className='form-control checkbox-group-bunkering'>
      <label className='checkbox-group-bunkering__label'>{label}</label>
      <div className='checkbox-group-bunkering__checkboxes'>
        <Field name={name} {...rest}>
          {({ form, field }) => {
            const handleChange = (optionValue, checked) => {
              let values = [];
              values = values.concat(field.value || []);
              if (checked) {
                values.push(optionValue);
              } else {
                const index = values.indexOf(optionValue);
                if (index !== -1) {
                  values.splice(index, 1);
                }
              }
              form.setFieldValue(field.name, values);
            };

            return options.map((option) => {
              return (
                <div
                  onClick={() => {
                    const checked = !field.value?.includes(option.value);
                    handleChange(option.value, checked);
                  }}
                  className={'checkbox' + (field.value.includes(option.value) ? ' checked' : '')}
                  key={option.key}>
                  <input
                    type='checkbox'
                    id={option.value}
                    {...field}
                    value={option.value}
                    checked={field.value.includes(option.value)}
                  />
                  <label>{option.key}</label>
                </div>
              );
            });
          }}
        </Field>
      </div>

      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
