import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { selectGeneralData } from '../../captains-form/slices/portCallSlice';
import { FormRefContext } from '../../global/context/FormRefContext';
// @ts-ignore
import ChevronDown from '@navozyme/uikit/dist/atoms/Icons/ChevronDown';
// @ts-ignore
import ChevronUp from '@navozyme/uikit/dist/atoms/Icons/ChevronUp';
import '../styles/sidebar.scss';

const setClassName = ({ isActive, isPending }: { isActive: boolean; isPending: boolean }) =>
  isActive ? 'active' : isPending ? 'pending' : '';

export type SidebarMenuItem = {
  title: string;
  navLink?: string;
  children?: SidebarMenuItem[];
};

const passengerShipTypes = [
  'Inland Passenger Ship',
  'Inland, Passenger Ship without Accommodation',
  'Inland, Passenger Ship, Ferry, Cruise ship',
  'Passenger',
  'Passenger Landing Craft',
  'Passenger or Cargo Ship',
  'Passenger Ship',
  'Ro-Ro or Passenger Ship',
  'Cruise Ship',
  'Ferry',
  'RoPax',
  'High-Speed Craft',
  'Yacht',
  'Tour Boat',
  'Expedition Ship',
  'Water Taxi',
];

export const isPassengerShip = (shipType: string) => {
  return passengerShipTypes.includes(shipType);
};

export default function Sidebar() {
  const { formContextRef, multiFormRef, skipOrBack, setSkipOrBack } = useContext(FormRefContext);
  const location = useLocation();
  const params = useParams();
  const initialRender = useRef(true);
  const previousLocation = useRef(location);
  const { vessel } = useSelector(selectGeneralData);

  const cargoShipMenuItems: SidebarMenuItem[] = [
    {
      title: 'Port call data',
      navLink: '/port-call-data',
    },
    {
      title: 'Vessel Data',
      navLink: '/vessel-data',
    },
    {
      title: 'Inspections',
      navLink: '/inspections',
    },
    {
      title: 'Crew',
      navLink: '/crew',
    },
    {
      title: 'Cargo Information',
      children: [
        {
          title: 'General Information',
          navLink: '/cargo',
        },
        {
          title: 'Dangerous Goods',
          navLink: '/dangerous-goods',
        },
      ],
    },
    {
      title: 'SOLAS and ISPS code',
      navLink: '/solas-and-isps-code',
    },
    {
      title: 'Marpol waste',
      navLink: '/marpol',
    },
    {
      title: 'Health Declaration',
      navLink: '/health',
    },
    {
      title: 'Local Formalities',
      navLink: '/local-formalities',
    },
    {
      title: 'Ship Certificates',
      navLink: '/ship-certificates',
    },
    {
      title: 'General Declaration',
      navLink: '/general-declaration',
    },
    {
      title: 'Summary',
      navLink: '/summary',
    },
  ];

  const [sidebarMenuItems, setSidebarMenuItems] = useState<SidebarMenuItem[]>(cargoShipMenuItems);
  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});

  const getSidebarMenuItems = (shipType: string) => {
    if (isPassengerShip(shipType)) {
      const menuItems = [...cargoShipMenuItems];
      const passengerMenuItem: SidebarMenuItem = {
        title: 'Passenger',
        navLink: '/passengers',
      };
      menuItems.splice(4, 0, passengerMenuItem);
      return menuItems;
    } else {
      return cargoShipMenuItems;
    }
  };

  useEffect(() => {
    if (vessel) {
      const shipType: string = vessel.shipType ?? 'Cargo';
      localStorage.setItem('shipType', shipType);
      const menuItems = getSidebarMenuItems(shipType);
      setSidebarMenuItems(menuItems);
    }
  }, [vessel]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (previousLocation.current.pathname !== location.pathname) {
      if (!skipOrBack) {
        formContextRef?.current?.submitForm();
        multiFormRef?.current?.submitForm();
      }
      if (formContextRef) formContextRef.current = null;
      if (multiFormRef) multiFormRef.current = null;
      setSkipOrBack(false);
    }
    previousLocation.current = location;
  }, [location]);

  // Expand the parent section if the current route matches a child navLink
  useEffect(() => {
    sidebarMenuItems.forEach((item, index) => {
      if (item.children) {
        const childMatch = item.children.find((child) => location.pathname.includes(child.navLink!));
        if (childMatch) {
          setExpandedItems((prevState) => ({
            ...prevState,
            [index]: true,
          }));
        }
      }
    });
  }, [location.pathname, sidebarMenuItems]);

  const toggleExpand = (index: number) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  if (!location.pathname.includes('/welcome') && !location.pathname.includes('/port-clearance-form-request')) {
    return (
      <aside className='sidebar'>
        <h4>Port clearance form</h4>
        <ul>
          {sidebarMenuItems.map((item, index) => {
            const isExpanded = expandedItems[index];

            if (item.children) {
              return (
                <li key={index} className='sidebar__multiple-items'>
                  <div className='sidebar__multiple-items-parent' onClick={() => toggleExpand(index)}>
                    <span>{index + 1}. {item.title}</span>
                    <span className='sidebar__icon'>{isExpanded ? <ChevronUp /> : <ChevronDown />}</span>
                  </div>
                  {isExpanded && (
                    <ul className='sidebar__multiple-items-children'>
                      {item.children.map((child, childIndex) => (
                        <li key={childIndex}>
                          <NavLink to={`/${params.uid}${child.navLink}`} className={setClassName}>
                            {child.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            }
            return (
              <li key={index} className='sidebar__single-item'>
                <NavLink to={`/${params.uid}${item.navLink}`} className={setClassName}>
                  {index + 1}. {item.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </aside>
    );
  }

  return null;
}
