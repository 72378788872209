import React, { useState } from 'react';
import Button from "../../../../layout/components/Button";
import SendConfirmationModal from './SendConfirmationModal';
import SendFormModal from './SendFormModal';
import "../styles/instructions.scss";


const SendFormLayout = function (props) {
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  return (
    <>
      <SendFormModal
        isInstructionsModalOpen={isInstructionsModalOpen}
        setIsInstructionsModalOpen={setIsInstructionsModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      />
      <SendConfirmationModal 
        isConfirmationModalOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      />
      <Button 
        className="generate-form-btn" 
        onClick={() => { setIsInstructionsModalOpen(true) }}
      >
        Send to agent
      </Button>
    </>
  );
}

export default SendFormLayout;