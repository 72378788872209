import React from 'react';
import { Field, ErrorMessage } from "formik";
import TextError from './TextError';
import ReactSelect from './ReactSelect';
import "../styles/multi-select.scss";

export default function MultiSelect(props) {
  const { label, name, onInputChange, onChange, ...rest } = props;

  return (
    <div className="form-control multi-select">
      {label && <label className="input__label" htmlFor={name}>{label}</label>}
      {props.sublabel && <span className="sublabel">{props.sublabel}</span>}
      <Field
        name={name}
        id={name}
        onChange={onChange}
        isMulti={true}
        component={ReactSelect}
        onInputChange={onInputChange}
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>

  );
}

