import React, {useEffect, useMemo} from "react";
import { useTable, useFlexLayout } from "react-table";
import Button from '../../layout/components/Button';
import PlusIcon from "../styles/images/plusIcon.svg";
import EditIcon from "../styles/images/editIcon.svg";
import DeleteIcon from "../styles/images/deleteIcon.svg";
import "../styles/table.scss";

interface TableProps {
  title: string;
  data: any[];
  columns: any[];
  useRowNumbers?: boolean;
  disabled?: boolean;
  editingMode: boolean;
  addButtonText: string;
  onAddButtonClick?: () => void | null;
  onEdittingModeButtonClick?: () => void | null;
  setEditingMode: (newValue: boolean) => void;
  setIsAddModalOpen: (newValue: boolean) => void;
  setIsDeleteModalOpen: (newValue: boolean) => void;
  onEditButtonClick: (index: number) => void;
  onDeleteButtonClick: (index: number) => void;
}

export interface DeleteModalData {
  index: number | null;
  showModal: boolean;
}

export const Table: React.FC<TableProps> = ({
  title,
  data,
  columns,
  editingMode,
  useRowNumbers = false,
  addButtonText,
  onAddButtonClick = null,
  onEdittingModeButtonClick = null,
  setEditingMode,
  setIsAddModalOpen,
  setIsDeleteModalOpen,
  onEditButtonClick,
  onDeleteButtonClick,
  disabled = false
}) => {
  const columnsMemoized = useMemo(() => {
    return columns;
  }, [columns]);
  const dataMemoized = useMemo(() => {
    return data;
  }, [data]);

  useEffect(()=>{
    if (disabled){
      setEditingMode(false)
    }
  },[disabled])


  const tableInstance = useTable({
    columns: columnsMemoized,
    data: dataMemoized
  }, useFlexLayout);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <div className="table-preheader">
          {title ? (
          <div className="table-preheader__title">
            { title }
          </div>
          ):(<div></div>)}

          <div className="table-preheader__control-btns">
          {editingMode ? (
              <>
                <Button disabled={disabled} iconLeft={EditIcon} onClick={() => setEditingMode(false)} theme="light-table">Stop Editing</Button>
                <Button disabled={disabled} iconLeft={DeleteIcon} onClick={() => { setIsDeleteModalOpen(true) }} theme="danger-table">Delete All</Button>
              </>
            ) : (
              <>
                <Button disabled={disabled} iconLeft={PlusIcon} onClick={() => {
                  if (onAddButtonClick) onAddButtonClick();
                  setIsAddModalOpen(true)}}>{ addButtonText }</Button>
                <Button disabled={disabled} iconLeft={EditIcon} onClick={() => {
                  if (onEdittingModeButtonClick) onEdittingModeButtonClick()
                  setEditingMode(true)}} theme="light-table">Edit table</Button>
              </>
            )}
          </div>
        </div>
      <div className="table-body">
        <table className="table" {...getTableProps()}>
          <thead>
            {
              headerGroups.map((headerGroup: any) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      <>
                        {editingMode && (
                          <>
                            <th className="editing-placeholder"></th>
                            <th className="editing-placeholder"></th>
                          </>
                        )}
                        {useRowNumbers && <th className="head-number-cell"><div>No.</div></th>}

                        {headerGroup.headers.map((column: any) => (
                          <th {...column.getHeaderProps()}>
                            <div>
                              {column.render("Header")}
                            </div>
                          </th>
                        ))
                        }
                      </>
                    }
                  </tr>
                )
              })
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              rows.map((row: any, i: number) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {
                      <>
                        {editingMode && (
                          <>
                            <td className="delete-btn" onClick={disabled?()=>{}:() => onDeleteButtonClick(i)}><img src={DeleteIcon} alt="verticalDotsIcon" /></td>
                            <td className="edit-btn" onClick={disabled?()=>{}:() => onEditButtonClick(i)}><img src={EditIcon} alt="verticalDotsIcon" /></td>
                          </>
                        )}
                        {useRowNumbers && <td className={"body-number-cell"}>{i + 1}</td>}
                        {
                          row.cells.map((cell: any) => {
                            return (
                              <td className={"body-cell " + cell.column.id} {...cell.getCellProps()}>
                                <span>{cell.render("Cell")}</span>
                              </td>
                            )
                          })
                        }
                      </>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
};

export default Table;
