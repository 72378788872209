import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRequiredDocuments, selectDocuments, generatePreArrivalDocuments, generatePreDepartureDocuments } from '../../documents/slices/documentsSlice';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import Page from '../../../../layout/components/Page';
import ProgressBar from '../../../../layout/components/ProgressBar';
import SummaryDocumentsBlock from './SummaryDocumentsBlock'
import "../styles/summary.scss";
import {NotificationAlert} from "../../../../global/alertModal/alertModal";

const TERMINAL_SUBCATEGORY = 'Terminals';
const PILOTAGE_SUBCATEGORY = 'Pilotage';
const HEALTH_SUBCATEGORY = 'Health';

function Summary() {
  const params = useParams();
  const dispatch = useDispatch();
  const portCallDocuments = useSelector(selectDocuments)?.documents;
  const [showLoadingAllDocuments, setShowLoadingAllDocuments] = useState(false)
  const [showLoadingSpecificDocument, setShowLoadingSpecificDocument] = useState(false)

  useEffect(() => {
    setShowLoadingAllDocuments(true)
    dispatch(generatePreArrivalDocuments({uid: params.uid})).finally(()=>{
      dispatch(generatePreDepartureDocuments({uid: params.uid})).finally(()=>{
        dispatch(getRequiredDocuments({ uid: params.uid })).finally(()=> {
          setShowLoadingAllDocuments(false)
        })
      })
    })
  }, [])


  return (
    <Page>
      <div className="summary">
        <NotificationAlert id={"Alert-Information"} alertType={"information"}
                           isOpened={showLoadingAllDocuments || showLoadingSpecificDocument}
                           setIsOpened={(open)=>{
                             setShowLoadingAllDocuments(open)
                             setShowLoadingSpecificDocument(open)
                           }}
                           title={"Document generation in progress"}
                           message={"Your documents will be ready in a few seconds."}
                           showCloseButton={false}
        />
        <ProgressBar percentage={"100"} pageNumber={1} totalPages={"1"} />
        <PageHeader
          title={"Summary"}
          subtitle={`If you see missing or incorrect information, please correct it going to the section in the left side menu.`} />

        <SummaryDocumentsBlock
          title={'General pre-arrival documents'}
          emptyDocumentsMessage={'There are no general pre-arrival documents.'}
          documents={portCallDocuments?.preArrivalDocuments}
          uploadSlice={generatePreArrivalDocuments}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
        />
        <SummaryDocumentsBlock
          title={'General pre-departure documents'}
          emptyDocumentsMessage={'There are no general pre-arrival documents.'}
          documents={portCallDocuments?.preDepartureDocuments}
          uploadSlice={generatePreDepartureDocuments}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
          />
        <SummaryDocumentsBlock
          title={'Local formalities - cargo documents'}
          emptyDocumentsMessage={'There are no cargo documents.'}
          documents={portCallDocuments?.cargoDocuments}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
        />
        {portCallDocuments?.localFormalities && portCallDocuments?.localFormalities.length > 0 && (
          <h3 className="document-type-title">{"Local formalities - safety documents"}</h3>
        )}
        <SummaryDocumentsBlock
          subtitle={'Terminal documents'}
          emptyDocumentsMessage={'No documents requested.'}
          documents={portCallDocuments?.localFormalities}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
          filter={TERMINAL_SUBCATEGORY} />
        <SummaryDocumentsBlock
          subtitle={'Pilotage documents'}
          emptyDocumentsMessage={'No documents requested.'}
          documents={portCallDocuments?.localFormalities}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
          filter={PILOTAGE_SUBCATEGORY} />
        <SummaryDocumentsBlock
          subtitle={'Health documents'}
          emptyDocumentsMessage={'No documents requested.'}
          documents={portCallDocuments?.localFormalities}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
          filter={HEALTH_SUBCATEGORY} />

        <SummaryDocumentsBlock
          title={'Ship certificates requested by agent'}
          emptyDocumentsMessage={'There are no ship certificates.'}
          updateDocuments={showLoadingAllDocuments}
          setShowLoadingAlert={setShowLoadingSpecificDocument}
          documents={portCallDocuments?.requestedCertificates} />

      </div>
      <SkipAndNextButtons
        back={`/${params.uid}/general-declaration`}
        noNext={true}
      />
    </Page >
  );
}

export default Summary;
