import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import EffectsListTable from './EffectsListTable';
import { selectCrew, updateCrew } from '../../../slices/portCallSlice';
import { FormRefContext } from '../../../../global/context/FormRefContext';
import { isPassengerShip } from '../../../../layout/components/Sidebar';

const validationSchema = Yup.object({
  declareEffectsOptions: Yup.boolean(),
});

const declareEffectsOptions = booleanOptionsForRadioGroup();

function DeclareEffects() {
  const formRef = useRef();
  const formContextRef = useContext(FormRefContext);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const crew = useSelector(selectCrew);
  const shipType = localStorage.getItem('shipType');
  const nextUrl = isPassengerShip(shipType)
    ? `/${params.uid}/passengers`
    : `/${params.uid}/dangerous-goods`;

  const initalValues = {
    declareEffectsOptions: crew.crewEffects.length ? true : false,
  };

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, crew]);

  const handleSubmit = async (values, onSubmitProps) => {
    dispatch(
      updateCrew({
        uid: params.uid,
        data: {
          crewEffects: crew.crewEffects,
        },
      })
    );
  };

  return (
    <div className='declare-effects'>
      <PageHeader title={'Crew'} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={initalValues}
        enableReinitialize
        validationSchema={validationSchema}>
        {({ values }) => {
          return (
            <>
              <Form className='declare-effects-form'>
                <RadioButtonsCustom
                  name='declareEffectsOptions'
                  label='Any crew’s effects to declare?'
                  options={declareEffectsOptions}
                />
              </Form>
              {values.declareEffectsOptions && <EffectsListTable />}
            </>
          );
        }}
      </Formik>
      <SkipAndNextButtons
        onClick={() => navigate(`${nextUrl}`)}
        next={`${nextUrl}`}
        back={`/${params.uid}/crew/departure-crew`}
      />
    </div>
  );
}

export default DeclareEffects;
