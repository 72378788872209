import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import {
  selectMarpol,
  setExemptionForDeliveringWaste,
  setWillDischargeWaste,
  updateDeliveryWaste,
} from '../slices/marpolSlice';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import PageHeader from '../../../../layout/components/PageHeader';
import DatePicker from '../../../../forms/components/DatePicker';
import MultiSelect from '../../../../forms/components/MultiSelect';
import { selectGeneralData } from '../../../slices/portCallSlice';
import SearchPortsDropdown from "../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown";
import { NONE_WASTE_DISPOSAL_APPROACH } from "../utils/marpolUtils";
import usePortsRepository from '../../../../../hooks/usePortsRepository';
import { AnnexesTypesList } from '../utils/waste';
import {DatePickerTimeInput} from "@navozyme/uikit/dist/molecules/DatePicker";
import {Field} from "@navozyme/uikit/dist/atoms/Field";
import { FormRefContext } from '../../../../global/context/FormRefContext';

const validationSchema = Yup.object({
  wasteToDischarge: Yup.string()
})

const dischargeWasteOptions = booleanOptionsForRadioGroup();
const exemptionOptions = booleanOptionsForRadioGroup();

function ConfirmWaste() {
  const formRef = useRef();
  const {formContextRef} = useContext(FormRefContext)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const generalPortcallData = useSelector(selectGeneralData);
  const marpolData = useSelector(selectMarpol);
  const { relations, unlocode } = generalPortcallData;
  const { port } = usePortsRepository({ ports: relations?.ports, unlocode });

  const getNextUrl = () => `/${params.uid}/marpol/tables`;

  useEffect(() => {
    formContextRef.current = formRef.current; 
  }, [formContextRef, formRef, generalPortcallData, marpolData])

  const initialValues = {
    wasteToDischarge: marpolData.wasteToDischarge,
    marpolWasteData: marpolData.marpolWasteData ? {
      ...marpolData.marpolWasteData,
      lastWasteGeneratedDate: marpolData.marpolWasteData.lastWasteGeneratedDate ? new Date(marpolData.marpolWasteData.lastWasteGeneratedDate) : null,
      annexDischargedLastDelivery: marpolData.marpolWasteData.annexDischargedLastDelivery ?? []
    } : {
      exemptionForDeliveringWaste: null,
      capacityToReachNextPort: null,
      annexDischargedLastDelivery: [],
      lastWasteGeneratedDate: null,
    }
  };

  const getWasteWithNoneWasteToDeliver = () => {
    return (marpolData.waste || []).map(wasteItem => {
      return { ...wasteItem, wasteToBeDelivered: 0 }
    });
  }

  const handleSubmit = async (values, _) => {
    const { waste, ...finalValues } = values;

    if (finalValues.marpolWasteData.lastPortDischargeDropdown) {
      finalValues.marpolWasteData.lastPortDischarge = finalValues.marpolWasteData.lastPortDischargeDropdown.unlocode;
    }

    if (finalValues.marpolWasteData.nextPortWasteGeneratedDropdown) {
      finalValues.marpolWasteData.nextPortWasteGenerated = finalValues.marpolWasteData.nextPortWasteGeneratedDropdown.unlocode;
    }

    if (!finalValues.wasteToDischarge) {
      finalValues.wasteDisposalApproach = NONE_WASTE_DISPOSAL_APPROACH;
      finalValues.waste = getWasteWithNoneWasteToDeliver();
    }

    await dispatch(updateDeliveryWaste({
      marpolData: { ...finalValues },
      uid: params.uid
    }))
  };

  return (
    <div className="confirm-waste">
      <PageHeader title={"Marpol waste"} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}>
        {({ values, setValues, errors }) => (
          <Form className="confirm-waste-form">
            <RadioButtonsCustom
              onOptionClick={(val) => dispatch(setWillDischargeWaste({
                wasteToDischarge: val
              }))}
              name="wasteToDischarge"
              label={`Will you discharge waste in this port?`}
              options={dischargeWasteOptions} />

            <div className="last-waste">
              <RadioButtonsCustom
                name="marpolWasteData.exemptionForDeliveringWaste"
                label={`Do you have an exemption for delivering vessel waste at the Port of ${port && port.name}?`}
                onOptionClick={(val) => dispatch(setExemptionForDeliveringWaste({
                  exemptionForDeliveringWaste: val
                }))}
                options={exemptionOptions} />
              {values.exemptionForDeliveringWaste && (
                  <div className="upload-certificate-section">
                    <Field label="Exemption certificate expiration date" className="field_date_picker">
                      <DatePickerTimeInput
                          key={initialValues?.marpolWasteData?.lastWasteGeneratedDate + "lastWasteGeneratedDate"}
                          name="marpolWasteData?.lastWasteGeneratedDate"
                          sizeInput="large"
                          minDate={new Date()}
                          defaultValue={
                            initialValues?.marpolWasteData?.lastWasteGeneratedDate
                          }
                          action={({ isoDate }) =>
                              setValues({...values, marpolWasteData: {...values?.marpolWasteData, lastWasteGeneratedDate: isoDate ? new Date(isoDate):null}})
                          }
                          hasError={errors?.marpolWasteData?.lastWasteGeneratedDate}
                          errorMessage={errors?.marpolWasteData?.lastWasteGeneratedDate}
                      />
                    </Field>
                  </div>
              )}
              <RadioButtonsCustom
                name="marpolWasteData.capacityToReachNextPort"
                label={`Do you have sufficient capacity to store the waste accumulated until you reach the next port of call?`}
                options={exemptionOptions} />
            </div>
            <div className="last-waste-delivery-section">
              <h3>Last waste delivery</h3>

              <div className="last-waste-delivery-section__fields">
                <MultiSelect
                  name="marpolWasteData.annexDischargedLastDelivery"
                  label="Annex discharged in last delivery"
                  options={AnnexesTypesList.map(annexType => {
                    return {label: annexType.label, value: annexType.value}
                  })}
                  isMulti={true}
                  placeholder="Unknown" />
                <Field label="Date of last delivery" className="field_date_picker">
                  <DatePickerTimeInput
                      key={initialValues?.marpolWasteData?.lastWasteGeneratedDate + "lastWasteGeneratedDate"}
                      name="marpolWasteData.lastWasteGeneratedDate"
                      sizeInput="large"
                      maxDate={new Date()}
                      showTime={false}
                      defaultValue={
                        initialValues?.marpolWasteData?.lastWasteGeneratedDate
                      }
                      action={({ isoDate }) =>
                          setValues({...values, marpolWasteData:{...values?.marpolWasteData, lastWasteGeneratedDate: new Date(isoDate)}})
                      }
                      hasError={errors?.marpolWasteData?.lastWasteGeneratedDate}
                      errorMessage={errors?.marpolWasteData?.lastWasteGeneratedDate}
                  />
                </Field>
                <SearchPortsDropdown
                  name="marpolWasteData.lastPortDischargeDropdown"
                  label="Last port of discharge"
                  dropdownID="lastPortDischarge"
                  placeholder="Unknown" />
                <SearchPortsDropdown
                  name="marpolWasteData.nextPortWasteGeneratedDropdown"
                  label="Next port of discharge"
                  dropdownID="nextPortWasteGenerated"
                  placeholder="Unknown" />
              </div>
            </div>
            <SkipAndNextButtons
              next={getNextUrl()}
              back={`/${params.uid}/solas-and-isps-code/security-measures`}
              onClick={()=>navigate(getNextUrl())}
            />
          </Form>
        )}
      </Formik>
    </div >
  );
}

export default ConfirmWaste;
