import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPagedData } from '../../global/utils/fetchPagedData';
import { axiosInstance } from '../../../config/axios';
import auth from '../../../config/auth';

const initialState = {
  searchResults: [],
  ui: {
    shoudShowLoader: false
  }
};

export const searchPort = createAsyncThunk(
  'vessels/searchPort',
  async (searchData) => {
    let endpoint = `/ports/`;
    if (searchData.unlocode) {
      endpoint += `?unlocode=${searchData.unlocode}`
    } else if (searchData.portName) {
      endpoint += `?name=${searchData.portName}`
    }

    const data = await fetchPagedData(endpoint, "/ports")

    // mapping into format that dropdown expects
    return data.map((port) => {
      return {
        ...port,
        value: port.unlocode,
        label: port.name + ", " + port.countryCode
      }
    })
  }
);


export const searchPortByUnlocode = async ({ unlocode }) => {
  const headers = auth.getHeaders();
  const endpoint = `/ports/?unlocode=${unlocode}`;
  const response = await axiosInstance.get(endpoint, { headers });

  return response.data.data;
};


export const portsSlice = createSlice({
  name: 'ports',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // signOut: (state) => {
    //   state.jwt = null;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(searchPort.pending, (state) => {
        state.searchResults = [];
        state.ui.shoudShowLoader = true;
      })
      .addCase(searchPort.fulfilled, (state, action) => {
        state.searchResults = action.payload;
        state.ui.shoudShowLoader = false;
      })
      .addCase(searchPort.rejected, (state, action) => {
        state.ui.shoudShowLoader = false;
      });
  },
});

export const selectPortsSearchResults = (state) => state.ports.searchResults;
export const selectPortsLoadingState = (state) => state.ports.ui.shoudShowLoader;
export default portsSlice.reducer;
