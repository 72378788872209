import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Formik, Form, } from "formik";
import * as Yup from "yup";
import diff from "object-diff";
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import { API_REQUEST_FORMAT_DATE } from '../../../../global/utils/datetimes';
import Page from '../../../../layout/components/Page';
import PageHeader from '../../../../layout/components/PageHeader';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import DatePicker from '../../../../forms/components/DatePicker';
import {
  selectInspections, getInspections, updateInspections
} from '../../../slices/portCallSlice';
import { searchPort, selectSearchPortsResults } from "../../../../forms/components/search-ports-dropdown/slices/searchPortsSlice";
import Textarea from '../../../../forms/components/TextArea';
import ProgressBar from '../../../../layout/components/ProgressBar';
import SearchPortsDropdown from '../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown';
import "../styles/inspections.scss";
import {DatePickerTimeInput} from "@navozyme/uikit/dist/molecules/DatePicker";
import {Field} from "@navozyme/uikit/dist/atoms/Field";
import { FormRefContext } from '../../../../global/context/FormRefContext';

const validationSchema = Yup.object({});

function Inspections() {
  const formRef = useRef();
  const {formContextRef} = useContext(FormRefContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const dropdownIDToInitializePort = 'portLastExpandedInspectionInitial';
  const searchPortsResults = useSelector((state) => selectSearchPortsResults(state, dropdownIDToInitializePort)) || [];
  const inspections = useSelector(selectInspections);
  const unlocode = inspections.data.portLastExpandedInspection;
  const hasToRetrievePort = unlocode && unlocode.length === 5;
  const hasToInitializePort = hasToRetrievePort && searchPortsResults.length === 1;
  const mouInspectionOptions = booleanOptionsForRadioGroup();
  const cargoTanksConditionOptions = [
    { key: "Full", value: "Full" },
    { key: "Empty", value: "Empty" },
    { key: "Inerted", value: "Inerted" },
  ];
  const ballastTankConditionOptions = [
    { key: "Full", value: "Full" },
    { key: "Empty", value: "Empty" },
    { key: "Inerted", value: "Inerted" },
  ];

  useEffect(() => {
    formContextRef.current = formRef.current; 
  }, [formContextRef, formRef, inspections]);

  useEffect(() => {
    dispatch(getInspections(params.uid));
  }, []);

  useEffect(() => {
    if (hasToRetrievePort) {
      dispatch(searchPort({
        dropdownID: dropdownIDToInitializePort,
        searchData: { unlocode: unlocode }
      }));
    }
  }, [inspections.data.portLastExpandedInspection]);

  const initialValues = () => {
    const values = {
      ...inspections.data,
      dateLastExpandedInspection: inspections.data.dateLastExpandedInspection ? new Date(inspections.data.dateLastExpandedInspection) : null,
    }
    if (hasToInitializePort) {
      values.dropdownPortLastExpandedInspection = searchPortsResults[0];
    }
    return values;
  };

  const handleSubmit = async (values, _) => {
    const changedValues = diff(initialValues(), values);

    if (Object.keys(changedValues).length) {
      let finalValues = { ...values }
      if (values.isSubjectedToExpandedInspection) {

        if (values.dateLastExpandedInspection) {
          finalValues.dateLastExpandedInspection = format(new Date(values.dateLastExpandedInspection), API_REQUEST_FORMAT_DATE);
        } else {
          delete finalValues.dateLastExpandedInspection
        }

        if (values.dropdownPortLastExpandedInspection) {
          finalValues.portLastExpandedInspection = values.dropdownPortLastExpandedInspection.unlocode;
          delete finalValues.dropdownPortLastExpandedInspection
        }

      } else {
        finalValues = { isSubjectedToExpandedInspection: values.isSubjectedToExpandedInspection }
      }
      dispatch(updateInspections({
        uid: params.uid,
        data: finalValues
      }));
    }
  };

  return (
    <Page>
      <div className="inspections">
        <ProgressBar percentage={"100"} pageNumber={1} totalPages={"1"} />
        <PageHeader title={"Inspections"} />
        <div className="warning">
          <p className="warning__title">Ships subjected to a MOU expanded inspection:</p>
          <ul>
            <li>All ships with a high risk profile </li>
            <li>Passenger ships, oil tankers, gas carriers, chemical tankers or bulk carriers, older than 12 years of age.</li>
          </ul>
        </div>
        <Formik
          innerRef={formRef}
          onSubmit={handleSubmit}
          initialValues={initialValues()}
          enableReinitialize
          validationSchema={validationSchema}>
          {({ values, setValues, errors }) => (
            <Form className="inspactions-form">
              <div className="inspactions-form__controls">
                <div className="inspactions-form__first-row">
                  <RadioButtonsCustom name="isSubjectedToExpandedInspection" label="Is the ship subjected to MOU expanded inspection?" options={mouInspectionOptions} />
                </div>
                {values.isSubjectedToExpandedInspection && (
                  <>
                    <div className="inspactions-form__second-row">
                     <Field label="Date of last expanded inspection" className="field_date_picker">
                        <DatePickerTimeInput
                            key={initialValues()?.dateLastExpandedInspection + "dateLastExpandedInspection"}
                            name="dateLastExpandedInspection"
                            sizeInput="large"
                            maxDate={new Date()}
                            showTime={false}
                            defaultValue={
                              initialValues()?.dateLastExpandedInspection
                            }
                            action={({ isoDate }) =>
                                setValues({...values, dateLastExpandedInspection: new Date(isoDate)})

                            }
                            hasError={errors?.dateLastExpandedInspection}
                            errorMessage={errors?.dateLastExpandedInspection}
                        />
                      </Field>
                      <SearchPortsDropdown
                        name="dropdownPortLastExpandedInspection"
                        dropdownID="portLastExpandedInspection"
                        label="Port where it was carried out"
                        placeholder="Carried Out Port" />
                    </div>
                    <div className="inspactions-form__third-row">
                      <RadioButtonsCustom name="conditionOfCargoTanks" label="Condition of cargo tanks" options={cargoTanksConditionOptions} />
                      <RadioButtonsCustom name="conditionOfBallastTanks" label="Condition of ballast tanks" options={ballastTankConditionOptions} />
                      <Textarea name="plannedStatutorySurveys" rows={10} placeholder="Write here." label="Planned statutory surveys / Substantial maintenance and repair work" />
                    </div>
                  </>
                )}
              </div>
              <SkipAndNextButtons
                next={`/${params.uid}/crew`}
                back={`/${params.uid}/vessel-data`} 
                onClick={()=>navigate(`/${params.uid}/crew`)}/>
            </Form>
          )}
        </Formik>
      </div>
    </Page>
  );
}

export default Inspections;
