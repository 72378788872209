import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectBerth, getBerth, updateBerth, FIRST_BERTH_STAY_ID } from '../../../slices/portCallSlice';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import MultiSelect from '../../../../forms/components/MultiSelect';
import SkipAndNextButtons from './SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import RadioButtons from '../../../../forms/components/RadioButtonsCustom';
import '../../../styles/purpose.scss';
import { FormRefContext } from '../../../../global/context/FormRefContext';

const validationSchema = Yup.object({
  purposeOfCall: Yup.string(),
});

const multiSelectDropdownOptions = [
  { value: 'Alteration', label: 'Alteration' },
  { value: 'Alteration into a shipyard', label: 'Alteration into a shipyard' },
  { value: 'Biological stop', label: 'Biological stop' },
  { value: 'Cargo loading/unloading', label: 'Cargo loading/unloading' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Cruises', label: 'Cruises' },
  { value: 'Disembarkation of passengers', label: 'Disembarkation of passengers' },
  { value: 'Domestic traffic', label: 'Domestic traffic' },
  { value: 'Embarkation of passengers', label: 'Embarkation of passengers' },
  {
    value: 'Fishing and artisanal vessels in fresh fish loading and unloading operations',
    label: 'Fishing and artisanal vessels in fresh fish loading and unloading operations',
  },
  { value: 'Fishing inactivity', label: 'Fishing inactivity' },
  { value: 'Forced arrival', label: 'Forced arrival' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Lack of license', label: 'Lack of license' },
  { value: 'Launch', label: 'Launch' },
  { value: 'Legal deposit', label: 'Legal deposit' },
  { value: 'Major repair in shipyard', label: 'Major repair in shipyard' },
  { value: 'Official visit', label: 'Official visit' },
  { value: 'Provisioning', label: 'Provisioning' },
  { value: 'Provisioning in anchorage', label: 'Provisioning in anchorage' },
  { value: 'Provisioning in berth', label: 'Provisioning in berth' },
  { value: 'Provisioning in berth by barge', label: 'Provisioning in berth by barge' },
  { value: 'Provisioning of bunkering in anchorage', label: 'Provisioning of bunkering in anchorage' },
  { value: 'Provisioning of bunkering in berth', label: 'Provisioning of bunkering in berth' },
  { value: 'Repair afloat with crew personnel', label: 'Repair afloat with crew personnel' },
  {
    value: 'Repair at anchor with personal external to the crew',
    label: 'Repair at anchor with personal external to the crew',
  },
  { value: 'Scrapping', label: 'Scrapping' },
  { value: 'Scrapping in shipyard', label: 'Scrapping in shipyard' },
  { value: 'Ship in dry dock', label: 'Ship in dry dock' },
  { value: 'Shipyard construction', label: 'Shipyard construction' },
  { value: 'Transshipment of cargo', label: 'Transshipment of cargo' },
  { value: 'Vessel intended for mooring service', label: 'Vessel intended for mooring service' },
  { value: 'Vessel intended for pilotage service', label: 'Vessel intended for pilotage service' },
  { value: 'Vessel intended for towing service', label: 'Vessel intended for towing service' },
  { value: 'Vessels destined for works and dredging', label: 'Vessels destined for works and dredging' },
  {
    value: 'Others (submarine cable laying, oceanographic research, etc.)',
    label: 'Others (submarine cable laying, oceanographic research, etc.)',
  },
];

const suppliesOptions = booleanOptionsForRadioGroup();

function Purpose(changePageNavigationData) {
  const formRef = useRef();
  const { formContextRef, setNoOfPage } = useContext(FormRefContext);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const berth = useSelector(selectBerth);
  const firstBerthStay = berth.berthStays[0];
  const [isBunkeringSelected, SetIsBunkeringSelected] = useState(firstBerthStay?.suppliesBunkering || false);
  const handleBunkeringOption = (value) => {
    SetIsBunkeringSelected(value);
    setNoOfPage(value ? 6 : 5);
  };

  useEffect(() => {
    dispatch(getBerth(params.uid));
    setNoOfPage(firstBerthStay?.suppliesBunkering ? 6 : 5);
  }, []);

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, firstBerthStay]);

  const initialValues = {
    berthId: firstBerthStay?.berthId || FIRST_BERTH_STAY_ID,
    purposeOfCall: firstBerthStay?.purposeOfCall,
    suppliesFreshWater: firstBerthStay?.suppliesFreshWater || false,
    suppliesBunkering: firstBerthStay?.suppliesBunkering || false,
    suppliesIce: firstBerthStay?.suppliesIce || false,
    suppliesElectricity: firstBerthStay?.suppliesElectricity || false,
  };

  const handleSubmit = async (values, onSubmitProps) => {
    dispatch(
      updateBerth({
        uid: params.uid,
        data: { berthStay: { ...values } },
      })
    );
  };

  return (
    <div className='purpose'>
      <PageHeader title={'Port call data'} />
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}>
        <Form className='purpose-form'>
          <div className='purpose-form__controls'>
            <MultiSelect
              name='purposeOfCall'
              options={multiSelectDropdownOptions}
              isMulti={false}
              label='Which is your primary purpose of call?'
              sublabel='Choose one option from the list below or type directly.'
              placeholder='Purpose of call'
            />

            <div className='supplies'>
              <div className='supplies__title'>
                <h2>Supplies</h2>
                <p>Please indicate if the vessel will receive the following supplies during the port call.</p>
              </div>
              <div className='supplies__list'>
                <RadioButtons name='suppliesFreshWater' label='Fresh water' options={suppliesOptions} />
                <RadioButtons
                  name='suppliesBunkering'
                  label='Bunkering'
                  options={suppliesOptions}
                  onOptionClick={(value) => handleBunkeringOption(value)}
                />
                <RadioButtons name='suppliesIce' label='Ice' options={suppliesOptions} />
                <RadioButtons name='suppliesElectricity' label='Electricity' options={suppliesOptions} />
              </div>
            </div>
          </div>
          <SkipAndNextButtons
            next={
              isBunkeringSelected
                ? `/${params.uid}/port-call-data/bunkering-detail`
                : `/${params.uid}/port-call-data/drafts`
            }
            back={`/${params.uid}/port-call-data/ports`}
            onClick={() =>
              navigate(
                isBunkeringSelected
                  ? `/${params.uid}/port-call-data/bunkering-detail`
                  : `/${params.uid}/port-call-data/drafts`
              )
            }
          />
        </Form>
      </Formik>
    </div>
  );
}

export default Purpose;
