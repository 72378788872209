import * as Yup from 'yup';
import {getPortFacilitiesFromApi } from "../../../../forms/components/search-ports-dropdown/slices/searchPortFacilitiesSlice";
import {validationOverlapDateSchema} from "../../marpol/validators";
import {formatDateTimeInTable} from "../../../../global/utils/datetimes";

const LEVEL_1_KEY = "Level 1";
const LEVEL_2_KEY = "Level 2";
const LEVEL_3_KEY = "Level 3";

const LEVEL_1_VALUE = "LEVEL_1";
const LEVEL_2_VALUE = "LEVEL_2";
const LEVEL_3_VALUE = "LEVEL_3";

export const portCallsTableColumns = [
  {
    Header: "Date from",
    accessor: "arrivalDate",
    Cell: formatDateTimeInTable
  },
  {
    Header: "Date to",
    accessor: "departureDate",
    Cell: formatDateTimeInTable
  },
  {
    Header: "Port",
    accessor: "portName",
    wrap: true,
  },
  {
    Header: "Country",
    accessor: "portCountry"
  },
  {
    Header: "UNLOCODE",
    accessor: "portUnLoCode"
  },
  {
    Header: "Port Facility",
    accessor: "portFacilityName"
  },
  {
    Header: "Security Level",
    accessor: "portSecurityLevel",
    Cell: props => {
      if (props.value === LEVEL_1_VALUE) {
        return LEVEL_1_KEY;
      } else if (props.value === LEVEL_2_VALUE) {
        return LEVEL_2_KEY;
      } else if (props.value === LEVEL_3_VALUE) {
        return LEVEL_3_KEY;
      }

      return props.value;
    }
  }
];

export const portSecurityLevelOptions = [
  { key: LEVEL_1_KEY, value: LEVEL_1_VALUE },
  { key: LEVEL_2_KEY, value: LEVEL_2_VALUE },
  { key: LEVEL_3_KEY, value: LEVEL_3_VALUE },
];

export const portCallsValidationSchema = (editingRow, values, allItems, portcallEta)=> Yup.object().shape({
  arrivalDate: validationOverlapDateSchema(editingRow, values?.arrivalDate, values?.departureDate, allItems,"arrivalDate", "departureDate", portcallEta, true),
  departureDate: validationOverlapDateSchema(editingRow, values?.arrivalDate, values?.departureDate, allItems,"arrivalDate", "departureDate", portcallEta),
  portSecurityLevel: Yup.string().required("*Please select one"),
});

export const portCallInitialValues = {
  arrivalDate: null,
  departureDate: null,
  portNameDropdown: null,
  portCountry: "",
  portUnLoCode: "",
  portFacilityImoNumber: "",
  portFacilityName: "",
  portSecurityLevel: ""
};

export const getPortFacilities = async ({ portFacilities, portUnlocode }) => {
  const portFacilitiesFromExistingOne = portFacilities.filter(portFacility => portFacility.unlocode === portUnlocode);
  if (portFacilitiesFromExistingOne.length > 0) return portFacilitiesFromExistingOne;

  return await getPortFacilitiesFromApi({ portUnlocode: portUnlocode });
};
