import React, { useContext, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getBerth, selectBerth, updateBerth, FIRST_BERTH_STAY_ID } from '../../../slices/portCallSlice';
import PageHeader from '../../../../layout/components/PageHeader';
import NumberInputWithTwoDecimals from '../../../../forms/components/NumberInputWithTwoDecimals';
import SkipAndNextButtons from './SkipAndNextButtons';
import '../.././../styles/draft.scss';
import { FormRefContext } from '../../../../global/context/FormRefContext';

const validationSchema = Yup.object({
  arrivalDraft: Yup.number()
    .min(0.1, 'Minimum number is 0.1')
    .max(49.99, 'This number is very high. Check it again.')
    .test('is-decimal', 'Only two decimal places are allowed', (value) => {
      if (value) {
        return /^\d+(\.\d{1,2})?$/.test(value.toString());
      }
      return true;
    }),
  departureDraft: Yup.number()
    .min(0.1, 'Minimum number is 0.1')
    .max(49.99, 'This number is very high. Check it again.')
    .test('is-decimal', 'Only two decimal places are allowed', (value) => {
      if (value) {
        return /^\d+(\.\d{1,2})?$/.test(value.toString());
      }
      return true;
    }),
});

function Draft() {
  const formRef = useRef();
  const { formContextRef, noOfPage } = useContext(FormRefContext);
  const dispatch = useDispatch();
  const params = useParams();
  let navigate = useNavigate();
  const berth = useSelector(selectBerth);

  const firstBerthStay = berth?.berthStays[0];
  const initialValues = {
    arrivalDraft: typeof firstBerthStay?.arrivalDraft === 'number' ? firstBerthStay.arrivalDraft : '',
    departureDraft: typeof firstBerthStay?.departureDraft === 'number' ? firstBerthStay.departureDraft : '',
  };

  useEffect(() => {
    dispatch(getBerth(params.uid));
  }, []);

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, firstBerthStay]);

  const handleSubmit = async (values, _) => {
    const finalValues = {
      berthId: firstBerthStay.berthId || FIRST_BERTH_STAY_ID,
    };

    if (values.arrivalDraft) {
      finalValues.arrivalDraft = Number(values.arrivalDraft);
    }

    if (values.departureDraft) {
      finalValues.departureDraft = Number(values.departureDraft);
    }

    dispatch(
      updateBerth({
        uid: params.uid,
        data: { berthStay: { ...finalValues } },
      })
    );
  };

  return (
    <div className='draft'>
      <PageHeader title={'Port call data'} />
      <h3>What is your draft at arrival and departure?</h3>
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}>
        <Form className='draft-form'>
          <div className='draft-form__controls'>
            <NumberInputWithTwoDecimals
              name='arrivalDraft'
              min='0'
              placeholder='Arrival Draft'
              label='Arrival draft (m)'
            />
            <NumberInputWithTwoDecimals
              name='departureDraft'
              min='0'
              placeholder='Departure Draft'
              label='Departure draft (m)'
            />
          </div>
          <SkipAndNextButtons
            next={`/${params.uid}/port-call-data/questions`}
            back={
              noOfPage === 6
                ? `/${params.uid}/port-call-data/bunkering-detail`
                : `/${params.uid}/port-call-data/purpose`
            }
            onClick={() => navigate(`/${params.uid}/port-call-data/questions`)}
          />
        </Form>
      </Formik>
    </div>
  );
}

export default Draft;
