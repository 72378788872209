import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import diff from "object-diff";
import { selectCrew, selectGeneralData } from '../../../slices/portCallSlice';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import Input from '../../../../forms/components/Input';
import Button from '../../../../layout/components/Button';
import editIcon from "../../vessel-data/styles/images/editIcon.svg"
import { useDispatch, useSelector } from 'react-redux';
import DiscardButton from '../../../../layout/components/DiscardButton';
import MultiSelect from '../../../../forms/components/MultiSelect';
import { countryListAllIsoData } from '../../../../global/utils/countriesList';
import { updateCrew } from '../../../slices/portCallSlice';
import "../styles/confirm-data.scss";
import { FormRefContext } from '../../../../global/context/FormRefContext';


const validationSchema = Yup.object({
  masterName: Yup.string(),
  masterSurname: Yup.string(),
  middleName: Yup.string(),
  nationality: Yup.string(),
  telephoneNumber: Yup.string().matches(/^[+\d]([0-9]+\s)*[0-9]+$/, 'Invalid phone number'),
})

function ConfirmData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [formDisabled, setFormDisabled] = useState(true);
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);

  const { crewList } = useSelector(selectCrew);
  let master = crewList?.find(object => object.rank === 'Master');
  const foundArrivalCaptain = crewList?.filter(_ => _?.rank === 'Master').find((captain) => captain.isArrivalCaptain);
  if (foundArrivalCaptain) {
    master = crewList?.find(object => object.rank === 'Master' && object.isArrivalCaptain);
  }
  const masterName = master?.name;
  const masterSurname = master?.surname;
  const masterNationality = master?.nationality;
  const telephoneNumber = master?.telephoneNumber;

  const initialValues = {
    masterName,
    masterSurname,
    masterNationality,
    telephoneNumber
  };
  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, masterName, masterSurname, masterNationality, formDisabled]);

  const handleSubmit = async (values, onSubmitProps) => {
    const changedValues = diff(initialValues, values);
    if (Object.keys(changedValues).length > 0) {
      let objIndex = crewList?.findIndex(cl => cl?.rank === 'Master');
      const foundArrivalCaptain = crewList?.filter(_ => _?.rank === 'Master').find((captain) => captain.isArrivalCaptain);
      if (foundArrivalCaptain) {
        objIndex = crewList?.findIndex(cl => cl?.rank === 'Master' && cl.isArrivalCaptain);
      }
      if (objIndex != -1) {
        const updatedObj = { ...crewList[objIndex], name: values.masterName, surname: values.masterSurname, nationality: values.masterNationality, telephoneNumber: values.telephoneNumber };
        const updatedCrewList = [
          ...crewList.slice(0, objIndex),
          updatedObj,
          ...crewList.slice(objIndex + 1),
        ];
        dispatch(updateCrew({
          uid: params.uid,
          data: { crewList: updatedCrewList }
        }));
      }
    }
    setFormDisabled(true)
  };

  return (
    <div className="crew-master-data">
      <div className="crew-master-data__header">
        <PageHeader
          title={"Crew"}
          subtitle={`Please confirm that your name and nationality is correct`} />
        {formDisabled && (
          <Button
            onClick={() => setFormDisabled(false)}
            theme="light"
            iconLeft={editIcon}>Edit</Button>
        )}
        {!formDisabled && (
          <div className="save-and-discard-btns">
            <DiscardButton
              onClick={() => {
                formRef.current.resetForm();
                setFormDisabled(true)
              }}>
              Discard
            </DiscardButton>
            <Button
              onClick={() => {
                formRef.current.submitForm();
              }}
              theme="light"
              iconLeft={editIcon}>Save</Button>
          </div>
        )}
      </div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        <Form className="crew-master-data-form">
          <div className="crew-master-data-form__row">
            <Input disabled={formDisabled} name="masterName" placeholder="Given Names" label="Given Names" />
            <Input disabled={formDisabled} name="masterSurname" placeholder="Family Name" label="Family Name" />
            <MultiSelect
              disabled={formDisabled}
              name="masterNationality"
              options={countryListAllIsoData}
              isMulti={false}
              label="Nationality"
              placeholder="Choose"
            />
          </div>
          <div className="crew-master-data-form__row">
            <Input disabled={formDisabled} name="telephoneNumber" placeholder="Telephone number" label="Telephone number" />
          </div>
          <SkipAndNextButtons
            next={`/${params.uid}/crew/number`}
            back={`/${params.uid}/inspections`}
            onClick={() => navigate(`/${params.uid}/crew/number`)} />
        </Form>
      </Formik>

    </div>
  );
}

export default ConfirmData;
