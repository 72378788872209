import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  updateIsps,
  selectSolas,
  selectPortCallData,
  getPortCallData,
} from '../../../slices/portCallSlice';
import Table from '../../../../tables/components/Table';
import Button from '../../../../layout/components/Button';
import plusIcon from '../../../../tables/styles/images/plusIcon.svg';
import Modal from '../../../../layout/components/Modal';
import Input from '../../../../forms/components/Input';
import MultiSelect from '../../../../forms/components/MultiSelect';
import DatePicker, {
  DEFAULT_DATETIME_MASK,
} from '../../../../forms/components/DatePicker';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import { countryListAllIsoData } from '../../../../global/utils/countriesList';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import PageHeader from '../../../../layout/components/PageHeader';
import SearchPortsDropdown from '../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown';
import ConfirmationModal from '../../../../layout/components/ConfirmationModal';
import { validateSchema } from '../../../../global/utils/validateForms';
import { cleanEmptyData } from '../../../../global/utils/cleanEmptyData';
import {
  searchPortFacilities,
  selectSearchPortFacilitiesResults,
} from '../../../../forms/components/search-ports-dropdown/slices/searchPortFacilitiesSlice';
import {
  getPortFacilities,
  portCallInitialValues,
  portCallsTableColumns,
  portCallsValidationSchema,
  portSecurityLevelOptions,
} from './SolasPortCallsUtils';
import '../styles/solas-port-calls.scss';
import { DEFAULT_FORMAT_DATETIME } from '../../../../global/utils/datetimes';
import { DatePickerTimeInput } from '@navozyme/uikit/dist/molecules/DatePicker';
import { Field } from '@navozyme/uikit/dist/atoms/Field';
import { FormRefContext } from '../../../../global/context/FormRefContext';

function SolasPortCalls() {
  const formRef = useRef();
  const { formContextRef } = useContext(FormRefContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const solas = useSelector(selectSolas);
  const portCall = useSelector(selectPortCallData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortedLast10PortCalls, setSortedLast10PortCalls] = useState([]);
  const [
    sortedLast10PortCallsWithFacilityNames,
    setSortedLast10PortCallsWithFacilityNames,
  ] = useState([]);
  const [portFacilities, setPortFacilities] = useState([]);
  const [initialValues, setInitialValues] = useState({
    ...portCallInitialValues,
  });
  const [arrivalDate, setArrivalDate] = useState(
    initialValues?.arrivalDate ? new Date(initialValues?.arrivalDate) : null
  );
  const [departureDate, setDepartureDate] = useState(
    initialValues?.departureDate ? new Date(initialValues?.departureDate) : null
  );

  useEffect(() => {
    formContextRef.current = formRef.current;
  }, [formContextRef, formRef, solas, portCall]);

  useEffect(() => {
    if (!portCall?.data?.eta?.estimatedTime) {
      dispatch(getPortCallData(params.uid));
    }
  }, []);

  const handleSubmit = async (values, _) => {
    const finalValues = { ...values };

    values.arrivalDate
      ? (finalValues.arrivalDate = values.arrivalDate.toISOString())
      : (finalValues.arrivalDate = '');
    values.departureDate
      ? (finalValues.departureDate = values.departureDate.toISOString())
      : (finalValues.departureDate = '');

    values.portNameDropdown
      ? (() => {
          finalValues.portName = values.portNameDropdown.name;
          finalValues.portUid = values.portNameDropdown.uid;
        })()
      : (() => {
          finalValues.portName = '';
          finalValues.portUid = '';
        })();

    if (finalValues?.portNameDropdown) delete finalValues.portNameDropdown;

    cleanEmptyData(finalValues);
    if (isEditingRow) {
      handleSubmitEdition(finalValues);
    } else {
      handleSubmitCreation(finalValues);
    }
    setArrivalDate(null);
    setDepartureDate(null);
  };

  const handleSubmitCreation = (finalValues) => {
    const portCalls = [...sortedLast10PortCallsWithFacilityNames];
    portCalls.push(finalValues);

    dispatch(
      updateIsps({
        ispsData: {
          last10PortCalls: portCalls,
        },
        uid: params.uid,
      })
    );
    setIsModalOpen(false);
    setInitialValues({ ...portCallInitialValues });
  };

  const handleSubmitEdition = (finalValues) => {
    const newPortCalls = sortedLast10PortCalls.map((portCall, index) => {
      return index === editingRow ? finalValues : portCall;
    });

    dispatch(
      updateIsps({
        ispsData: {
          last10PortCalls: newPortCalls,
        },
        uid: params.uid,
      })
    );

    setIsModalOpen(false);
    setEditingRow(null);
    setInitialValues({ ...portCallInitialValues });
  };

  const portFacilityDropdownId = 'solasPortFacilityDropdownId';
  const searchPortFacilitiesResults =
    useSelector((state) =>
      selectSearchPortFacilitiesResults(state, portFacilityDropdownId)
    ) || [];
  const portFacilityOptions = searchPortFacilitiesResults.map((option) => {
    return { label: option.facilityName, value: option.imoPortFacilityNumber };
  });
  const [isEditingTable, setIsEditingTable] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const isEditingRow = editingRow !== null;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const sortIspPortCallsByDate = (a, b) => {
    const dateA = a.arrivalDate ? new Date(a.arrivalDate) : null;
    const dateB = b.arrivalDate ? new Date(b.arrivalDate) : null;

    if (dateA === null && dateB === null) return 0;
    if (dateA === null) return 1; // Place null/undefined dates at the end
    if (dateB === null) return -1; // Place null/undefined dates at the end

    return dateB - dateA;
  };

  useEffect(() => {
    const row = isEditingRow ? sortedLast10PortCalls[editingRow] : {};
    const portNameDropdown = {
      countryCode: row.portCountry ? row.portCountry : '',
      name: row.portName ? row.portName : '',
      unlocode: row.portUnLoCode ? row.portUnLoCode : '',
    };
    setInitialValues({
      arrivalDate: row.arrivalDate ? new Date(row.arrivalDate) : null,
      departureDate: row.departureDate ? new Date(row.departureDate) : null,
      portNameDropdown: row.portNameDropdown
        ? row.portNameDropdown
        : portNameDropdown.name
        ? portNameDropdown
        : null,
      portCountry: row.portCountry ? row.portCountry : '',
      portUnLoCode: row.portUnLoCode ? row.portUnLoCode : '',
      portName: row.portName ? row.portName : '',
      portFacilityImoNumber: row.portFacilityImoNumber
        ? row.portFacilityImoNumber
        : '',
      portSecurityLevel: row.portSecurityLevel ? row.portSecurityLevel : '',
    });
  }, [editingRow]);

  useEffect(() => {
    if (!initialValues.portUnLoCode) return;

    dispatch(
      searchPortFacilities({
        dropdownID: portFacilityDropdownId,
        portUnlocode: initialValues.portUnLoCode,
      })
    );
  }, [initialValues.portUnLoCode]);

  useEffect(() => {
    if (!solas.data.last10PortCalls) return;

    setSortedLast10PortCalls(
      [...solas.data.last10PortCalls].sort((a, b) => {
        return new Date(b.arrivalDate) - new Date(a.arrivalDate);
      })
    );
  }, [solas.data.last10PortCalls]);

  useEffect(() => {
    const fetchData = async () => {
      let newPortFacilities =
        portFacilities.length === 0
          ? solas.relations?.portFacilities || []
          : [...portFacilities];
      const portCalls = [...sortedLast10PortCalls];
      for (const portCallIndex in sortedLast10PortCalls) {
        const portCall = { ...sortedLast10PortCalls[portCallIndex] };
        if (portCall.portUnLoCode && portCall.portFacilityImoNumber) {
          const obtainedPortFacilities = await getPortFacilities({
            portUnlocode: portCall.portUnLoCode,
            portFacilities: newPortFacilities,
          });
          newPortFacilities = newPortFacilities.concat(obtainedPortFacilities);
          const portFacilityFound = obtainedPortFacilities.find(
            (portFacility) =>
              portFacility.imoPortFacilityNumber ===
              portCall.portFacilityImoNumber
          );

          if (portFacilityFound)
            portCall.portFacilityName = portFacilityFound.facilityName;
        }
        portCalls[portCallIndex] = portCall;
      }
      // sort the port calls by date
      portCalls.sort((d1, d2) => sortIspPortCallsByDate(d1, d2));
      setSortedLast10PortCallsWithFacilityNames(portCalls);
      setPortFacilities(newPortFacilities);
    };

    if (sortedLast10PortCalls) fetchData();
  }, [sortedLast10PortCalls]);

  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] =
    useState({
      index: null,
      showModal: false,
    });
  const [
    showConfirmDeleteAllMembersModal,
    setShowConfirmDeleteAllMembersModal,
  ] = useState(false);

  const onCloseModal = () => {
    setIsModalOpen(false);
    setInitialValues({ ...portCallInitialValues });
    setTimeout(() => setEditingRow(null), 300);
    setArrivalDate(null);
    setDepartureDate(null);
  };

  return (
    <div className='solas-port-calls-table'>
      <PageHeader
        title={'SOLAS and ISPS code'}
        subtitle={`Please, indicate the list of last 10 calls and port facility in chronological order (most recent call first):`}
      />
      <p>Ship pre-arrival security information form.</p>
      <Table
        useRowNumbers
        title={'Last port calls'}
        addButtonText={'Add port call'}
        data={sortedLast10PortCallsWithFacilityNames}
        columns={portCallsTableColumns}
        editingMode={isEditingTable}
        setEditingMode={setIsEditingTable}
        setIsAddModalOpen={setIsModalOpen}
        setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
        onEditButtonClick={(rowIndex) => {
          setEditingRow(rowIndex);
          setIsModalOpen(true);
        }}
        onDeleteButtonClick={(rowIndex) => {
          setConfirmDeleteMemberModalData({
            showModal: true,
            index: rowIndex,
          });
        }}
      />
      <Modal
        className={`add-port-call-modal`}
        title={'Add a port call'}
        showModal={isModalOpen}
        onCloseModal={onCloseModal}>
        <ConfirmationModal
          title='Have you finished editing?'
          subtitle='Some required fields have not been completed.'
          disabledSubtitle='You have to complete at least one field.'
          showModal={isConfirmationModalOpen}
          handleCloseModal={setIsConfirmationModalOpen}
          willDisableSave={formValues}
          onAcceptModal={() => {
            setIsConfirmationModalOpen(false);
            setIsModalOpen(false);
            handleSubmit(formValues);
          }}
          className=''
        />
        <div className='add-port-call-modal__content'>
          <Formik
            innerRef={formRef}
            onSubmit={handleSubmit}
            enableReinitialize
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            validate={(values) => {
              return validateSchema(
                values,
                portCallsValidationSchema(
                  editingRow,
                  values,
                  sortedLast10PortCallsWithFacilityNames,
                  portCall?.data?.eta?.estimatedTime
                ),
                setFormValues,
                setIsConfirmationModalOpen,
                setIsModalOpen
              );
            }}>
            {({ values, setValues, errors }) => {
              return (
                <Form className='add-port-call-form'>
                  <div className='add-port-call-form__row'>
                    <Field label='Date From' className='field_date_picker'>
                      <DatePickerTimeInput
                        key={initialValues?.arrivalDate + 'arrivalDate'}
                        name='arrivalDate'
                        sizeInput='large'
                        maxDate={
                          portCall?.data?.eta?.estimatedTime
                            ? new Date(portCall?.data?.eta?.estimatedTime)
                            : new Date()
                        }
                        showTime={true}
                        // openToDate={
                        //   initialValues?.arrivalDate || new Date().setTime(0)
                        // }
                        defaultValue={arrivalDate}
                        action={({ isoDate }) => {
                          setArrivalDate(new Date(isoDate));
                          setValues({
                            ...values,
                            arrivalDate: new Date(isoDate),
                          });
                        }}
                        hasError={errors?.arrivalDate}
                        errorMessage={errors?.arrivalDate}
                      />
                    </Field>
                    <Field label='Date To' className='field_date_picker'>
                      <DatePickerTimeInput
                        key={initialValues?.departureDate + 'departureDate'}
                        name='departureDate'
                        sizeInput='large'
                        minDate={
                          arrivalDate ? arrivalDate : initialValues?.arrivalDate
                        }
                        maxDate={
                          portCall?.data?.eta?.estimatedTime
                            ? new Date(portCall?.data?.eta?.estimatedTime)
                            : new Date()
                        }
                        showTime={true}
                        // openToDate={
                        //   initialValues?.departureDate || new Date().setTime(0)
                        // }
                        defaultValue={departureDate}
                        action={({ isoDate }) => {
                          setDepartureDate(new Date(isoDate));
                          setValues({
                            ...values,
                            departureDate: new Date(isoDate),
                          });
                        }}
                        hasError={errors?.departureDate}
                        errorMessage={errors?.departureDate}
                      />
                    </Field>
                  </div>
                  <div className='add-port-call-form__row'>
                    <SearchPortsDropdown
                      label='Port'
                      name='portNameDropdown'
                      onChange={(port) => {
                        setInitialValues({
                          ...values,
                          portNameDropdown: port,
                          portCountry: port.countryCode,
                          portUnLoCode: port.unlocode,
                        });
                      }}
                      dropdownID={values.portName}
                      placeholder='Type One'
                    />
                    <MultiSelect
                      name='portCountry'
                      options={countryListAllIsoData}
                      isMulti={false}
                      disabled
                      label='Country'
                      placeholder=''
                    />
                    <Input name='portUnLoCode' disabled label='UNLOCODE' />
                  </div>
                  <div className='add-port-call-form__row'>
                    <MultiSelect
                      label='Port Facility'
                      name='portFacilityImoNumber'
                      options={portFacilityOptions}
                      isMulti={false}
                    />
                  </div>
                  <div className='add-port-call-form__row'>
                    <RadioButtonsCustom
                      name='portSecurityLevel'
                      label='Ship Security Level'
                      options={portSecurityLevelOptions}
                    />
                  </div>
                  {isEditingRow ? (
                    <div className='add-member__form-submission-btns'>
                      <div className='discard-btn' onClick={onCloseModal}>
                        Discard
                      </div>
                      <Button type='submit'>Change Information</Button>
                    </div>
                  ) : (
                    <div className='add-member__form-submission-btns'>
                      <Button iconLeft={plusIcon} type='submit'>
                        Add
                      </Button>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
      <ConfirmDeleteModal
        title={
          <h3>
            Are you sure you want to{' '}
            <span>
              delete all the <br />
              crew members
            </span>{' '}
            from the list?
          </h3>
        }
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => {
          setShowConfirmDeleteAllMembersModal(false);

          dispatch(
            updateIsps({
              ispsData: {
                last10PortCalls: [],
              },
              uid: params.uid,
            })
          );
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this crew member?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false,
          });
        }}
        onDeleteClick={() => {
          const last10PortCalls = sortedLast10PortCalls.filter(
            (_, index) => index !== confirmDeleteMemberModalData.index
          );

          dispatch(
            updateIsps({
              ispsData: {
                last10PortCalls,
              },
              uid: params.uid,
            })
          );

          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false,
          });
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <SkipAndNextButtons
        onClick={() =>
          navigate(`/${params.uid}/solas-and-isps-code/security-measures`)
        }
        next={`/${params.uid}/solas-and-isps-code/security-measures`}
        back={`/${params.uid}/solas-and-isps-code/questions`}
      />
    </div>
  );
}

export default SolasPortCalls;
