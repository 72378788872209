import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import { searchPort, selectSearchPortsResults, selectSearchPortLoadingState } from "../slices/searchPortsSlice";
import PortsDropdown from './PortsDropdown';


function SearchPortsDropdown(props) {
	const { name, label, dropdownID, placeholder, getSpecificValue, value, ...rest } = props;
	const dispatch = useDispatch();

	const searchPortsResults = useSelector((state) => selectSearchPortsResults(state, props.dropdownID)) || [];
	const shouldShowLoadingInSearchPortDropdown = useSelector((state) => selectSearchPortLoadingState(state, props.dropdownID));
	const dropdownOptions = searchPortsResults;

	const handleSearchPortChanged = _.debounce((value) => {
		if (value && value.length >= 3) {
			dispatch(searchPort({
				dropdownID: props.dropdownID,
				searchData: { portName: value }
			}));
		};
	}, 500);

	const handleSearchPortChangeDebounced = (value) => {
		if (handleSearchPortChanged.cancel) {
			handleSearchPortChanged.cancel(); // If there is an existing debounce, cancel it
		}

		handleSearchPortChanged(value); // Start a new debounce with the latest value
	};


	function handleOptionSelection(val) {
		if (props.onChange) {
			props.onChange(val);
		}
	}
	
	return (
		<PortsDropdown
			name={name}
			onInputChange={handleSearchPortChangeDebounced}
			onChange={handleOptionSelection}
			options={dropdownOptions}
			isMulti={false}
			getSpecificValue = {getSpecificValue}
			label={label || ""}
			isLoading={shouldShowLoadingInSearchPortDropdown}
			placeholder={placeholder || "Type One"}
			{...rest}
		/>

	);
}

export default SearchPortsDropdown;
