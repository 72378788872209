import React from 'react';
import { Field, ErrorMessage } from "formik";
import TextError from './TextError';
import "../styles/textarea.scss";
import { getStyles } from '../helpers/random-form-helpers';


function CustomTextarea(props) {
  const { field, form, form: { errors }, ...rest } = props;
  return <textarea 
    {...field}
    {...rest}
    onChange={(e) => {
      let value = e.target.value;
      if (props.uppercase) {
        value = e.target.value.toUpperCase()
      }

      form.setFieldValue(field.name, value)

      if (rest.onChange) {
        rest.onChange(e);
      }
    }}
    style={form.touched[field.name] && getStyles(errors, field.name)} />
}


export default function Textarea(props) {
  const { label, name, ...rest } = props;

  return (
    <div className="form-control textarea">
      <label className="textarea__label" htmlFor={name}>{label}</label>
      <Field component={CustomTextarea} as="textarea" id={name} name={name} {...rest} />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
