import React from 'react';
import { Field, ErrorMessage } from "formik";
import TextError from './TextError';
import "../styles/input.scss";
import { getStyles } from '../helpers/random-form-helpers';
import {hasMoreThanNDecimalsDigits, howManyDecimalDigits} from "../../global/utils/helpers";

function CustomInput(props) {
  const { field, form, form: { errors }, ...rest } = props;

  return <input
    {...field}
    {...rest}
    onChange={(e) => {
      let value = e.target.value;
      if (props.uppercase) {
        value = e.target.value.toUpperCase()
      }
      if (props?.type && props.type === "number" && props?.step){
          const decimalDigits = howManyDecimalDigits(props?.step)
          !hasMoreThanNDecimalsDigits(value, decimalDigits) && form.setFieldValue(field.name, value)
      }else{
        form.setFieldValue(field.name, value)
      }

      if (rest.onChange) {
        rest.onChange(e);
      }
    }}
    style={form.touched[field.name] && getStyles(errors, field.name)} />
}

export default function Input(props) {
  const { label, name, ...rest } = props;

  return (
    <div className="form-control input">
      {label && (
        <label className="input__label" htmlFor={name}>{label}</label>
      )}
      <Field
        component={CustomInput}
        type="text"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

