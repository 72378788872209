import { getIn } from 'formik';

export class RadioGroupItem {
  key: string;
  value: any;

  constructor(key: string, value: any = null) {
    this.key = key;
    this.value = value || key;
  }
}

export function getStyles(errors: any, fieldName: any) {
  if (getIn(errors, fieldName)) {
    return {
      border: '2px solid #B4220E',
    };
  }
}

export const booleanOptionsForRadioGroup = (): RadioGroupItem[] => [
  { key: 'No', value: false },
  { key: 'Yes', value: true },
];

export const verticalBunkeringManifoldOptions = (): RadioGroupItem[] => [
  { key: 'Aft', value: 'AFT' },
  { key: 'Mid', value: 'MID' },
  { key: 'Forward', value: 'FORWARD' },
  { key: 'Superstr.', value: 'SUPERSTRUCTURE' },
];

export const horizontalBunkeringManifoldOptions = (): RadioGroupItem[] => [
  { key: 'Port', value: 'PORT' },
  { key: 'Centre', value: 'CENTRE' },
  { key: 'Starb.', value: 'STARBOARD' },
];
