import React, { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import Modal from "../../../../layout/components/Modal";
import Button from "../../../../layout/components/Button";
import PageHeader from '../../../../layout/components/PageHeader';
import { selectGeneralData, updatePortCallStatus } from "../../../slices/portCallSlice";
import { downloadDocument, generatePreArrivalDocuments, getRequiredDocuments, selectDocuments } from '../../documents/slices/documentsSlice';
import SendIcon from "../styles/images/send-icon.svg";
import DocumentIcon from "../styles/images/document-icon.svg";
import { useParams } from 'react-router-dom';
import { STATUS_OPEN_TO_AGENT } from '../../../../../config/portCall';
import "../styles/instructions.scss";


const SendFormModal = function (props) {
  const params = useParams();
  const dispatch = useDispatch();
  const tenPixelesOfTolerance = 10;
  const instructionsContainerRef = useRef(null);
  const generalPortcallData = useSelector(selectGeneralData);
  const [scrollCompleted, setScrollCompleted] = useState(false);
  const [documentDownloaded, setDocumentDownloaded] = useState(false);
  const { instructionsText, status } = generalPortcallData;
  const { instructionsDocuments } = useSelector(selectDocuments)?.documents;

  useEffect(() => {
    dispatch(getRequiredDocuments({ uid: params.uid }));
  }, [])

  useEffect(() => {
    if (status === STATUS_OPEN_TO_AGENT) props.setIsConfirmationModalOpen(true);
  }, [status])

  const isThereInstructionsDocument = () => {
    return (instructionsDocuments && instructionsDocuments.length > 0) ? true : false;
  };

  const isThereInstructionsText = () => {
    return (instructionsText && instructionsText.length > 0) ? true : false;
  };

  const areThereNoInstructions = () => {
    if (isThereInstructionsText()) return false;
    if (isThereInstructionsDocument()) return false;
    return true;
  };

  const handleAfterOpen = () => {
    const instructionsContainer = instructionsContainerRef.current;
    if (instructionsContainer && instructionsContainer.scrollHeight <= instructionsContainer.clientHeight) {
      setScrollCompleted(true);
    }
  };

  const handleScroll = event => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - tenPixelesOfTolerance) {
      setScrollCompleted(true);
    }
  };
  
  const renderEmptyBody = () => <>No further port call instructions.</>;

  const getInstructionsCssClass = () => areThereNoInstructions() ? 'instructions hide' : 'instructions';

  const disableSendButton = () => {
    if (areThereNoInstructions()) return false;
    if (isThereInstructionsDocument()) return !documentDownloaded;
    return !scrollCompleted;
  }

  const onDownloadDocumentClick = () => {
    const documentUuid = instructionsDocuments[0].documentUid;
    dispatch(downloadDocument({
      uiid: documentUuid,
      fileName: `port-call-instruction-${documentUuid}`
    }));
    setDocumentDownloaded(true);
  };

  const onSendFormClick = () => {
    const payload = {
      uid: params.uid,
      newStatus: STATUS_OPEN_TO_AGENT
    }
    props.setIsInstructionsModalOpen(false);
    
    dispatch(generatePreArrivalDocuments({uid: params.uid}));
    dispatch(updatePortCallStatus(payload));
  };

  return (
    <Modal
      className={`port-call-instructions-modal`}
      hideCloseBtn={true}
      showModal={props.isInstructionsModalOpen}
      onAfterOpen={handleAfterOpen}
      onCloseModal={() => { props.setIsInstructionsModalOpen(false); }}
    >
      <div className="port-call-instructions-modal__header">
        <ReactSVG src={SendIcon} />
        <PageHeader
          title={'Send form'}
          subtitle={'Before sending the form, review the Port Call Instructions.'} />
      </div>
      <div className="port-call-instructions-modal__body">
        {areThereNoInstructions() && renderEmptyBody()}
        {isThereInstructionsText() && (
          <div 
            className={getInstructionsCssClass()} 
            ref={instructionsContainerRef} 
            onScroll={handleScroll}> 
            {instructionsText}
          </div>
        )}
        {isThereInstructionsDocument() && (
          <Button 
            className="generate-form-btn light"
            onClick={() => { onDownloadDocumentClick() }} >
            <ReactSVG src={DocumentIcon} />
            Download instructions
          </Button>
        )}
      </div>
      <div className="port-call-instructions-modal__footer">
        <Button className="generate-form-btn light-table-no-border" onClick={() => { props.setIsInstructionsModalOpen(false) }} >
          Close
        </Button>
        <Button 
          disabled={disableSendButton()}
          className="generate-form-btn" 
          onClick={() => { onSendFormClick() }} 
        >
          Send form
        </Button>
      </div>
    </Modal>
  );
}

export default SendFormModal;