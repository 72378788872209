import React from 'react';
import { Field, ErrorMessage } from "formik";
import TextError from '../../TextError';
import ReactSelectPorts from '../../ReactSelectPorts';
import "../../../styles/multi-select.scss";

export default function PortsDropdown(props) {
  const { label, name, onInputChange, getSpecificValue, onChange, ...rest } = props;

  return (
    <div className="form-control multi-select">
      {label && <label className="input__label" htmlFor={name}>{label}</label>}
      {props.sublabel && <span className="sublabel">{props.sublabel}</span>}
      <Field
        name={name}
        id={name}
        onChange={onChange}
        isMulti={true}
        component={ReactSelectPorts}
        onInputChange={onInputChange}
        getSpecificValue={getSpecificValue}
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>

  );
}

