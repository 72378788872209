import SignatureCanvas from "react-signature-canvas";
import React, {useEffect, useRef, useState} from "react";
import "../styles/general-declaration.scss";
import {formatDate} from "../../../../global/utils/datetimes";

const MasterSignature = ({portCallData, title, subtitle, setSignature})=>{

    const signatureRef = useRef(null);

    useEffect(() => {
        if (portCallData?.masterSignature && signatureRef){
        signatureRef.current?.clear()
            signatureRef.current.fromDataURL(portCallData?.masterSignature)
        }
    }, [portCallData]);


    const onEndStrokeOnCanvas = () => {
        setSignature(signatureRef.current?.toDataURL())
    };

    const onClearSignature = ()=>{
        signatureRef.current?.clear()
    }

    return <>
        <label className={"masterSignatureTitle"}>{title}</label>
        <p className={"masterSignatureSubtitle"}>{subtitle}</p>
        <div className={"canvasDiv"}>
            <SignatureCanvas
                ref={signatureRef}
                clearOnResize={false}
                onEnd={onEndStrokeOnCanvas}
                canvasProps={{ className: "submitSignatureCanvas" }}
            />
            <button type={"button"} onClick={onClearSignature}>
                Clear
            </button>
        </div>
        {portCallData?.masterSignatureTimestamp && <p>{`Signature date: ${formatDate(portCallData?.masterSignatureTimestamp)}`}</p>}
    </>

}

export default MasterSignature
