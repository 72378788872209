import React, {useContext, useEffect, useRef, useState} from 'react';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import SkipAndNextButtons from '../../port-call-data/components/SkipAndNextButtons';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../../../layout/components/PageHeader';
import Page from '../../../../layout/components/Page';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import Textarea from '../../../../forms/components/TextArea';
import ProgressBar from '../../../../layout/components/ProgressBar';
import {
  getGeneralDeclaration, getPortCall, selectGeneralData,
  selectGeneralDeclaration,
  updateGeneralDeclaration, updateMasterSignature
} from '../../../slices/portCallSlice';
import "../styles/general-declaration.scss";
import MasterSignature from "./MasterSignature";
import { FormRefContext } from '../../../../global/context/FormRefContext';

const generalDeclarationOptions = [
  { key: "I hereby declare that all information provided is true.", value: true },
];


function GeneralDeclaration() {
  const formRef = useRef();
  const {formContextRef} = useContext(FormRefContext)
  let navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const generalDeclaration = useSelector(selectGeneralDeclaration);
  const portCallData = useSelector(selectGeneralData)

  const [signature, setSignature] = useState(null)

  useEffect(() => {
    dispatch(getGeneralDeclaration(params.uid));
    dispatch(getPortCall(params.uid))
  }, [])

  useEffect(() => {
    formContextRef.current = formRef.current; 
  }, [formContextRef, formRef,generalDeclaration, portCallData])

  const initialValues = {
    generalDeclarationAccepted: generalDeclaration?.generalDeclarationAccepted,
    generalDeclarationRemarks: generalDeclaration?.generalDeclarationRemarks
  };

  const validationSchema = Yup.object({
    generalDeclarationAccepted: Yup.boolean()
      .required('You have to selected this option to proceed.'),
    generalDeclarationRemarks: Yup.string().nullable()
  });


  const handleSubmit = async (values, onSubmitProps) => {
    if (signature)
      dispatch(updateMasterSignature({image:signature, uid: params.uid}))

    dispatch(updateGeneralDeclaration({
      uid: params.uid,
      generalDeclaration: values
    }));
    dispatch(getPortCall(params.uid))

  };


  return (
    <Page>
      <div className="general-declaration">
        <ProgressBar percentage={"100"} pageNumber={1} totalPages={"1"} />
        <PageHeader
          title={"General Declaration"}
          subtitle={`Finally, could you please declare that the crew list is true and that all the Certificates and documents required to be on board in compliance with SOLAS 74/78, MARPOL 73/78, Memorandum of Understanding for Port State Control (MOU-PSC), and such other Certificates and documents as may be legally required depending on the type or class of vessel, are on board and in force and their validity extends at least until the date of arrival at the next port of destination?`}/>
        <Formik
          innerRef={formRef}
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}>
          <Form className="general-declaration-form">
            <div className="general-declaration-form__first-row">
              <RadioButtonsCustom name="generalDeclarationAccepted" options={generalDeclarationOptions} />
            </div>
            <div className="general-declaration-form__second-row">
              <Textarea name="generalDeclarationRemarks" rows={7} placeholder="Remarks (if any)" label="Remarks (if any)" />
            </div>
            <MasterSignature portCallData={portCallData} title={"Captain’s signature"} subtitle={"Draw your signature inside the designated space."} setSignature={setSignature}/>
            <SkipAndNextButtons
            onClick={()=>navigate(`/${params.uid}/summary`)}
              next={`/${params.uid}/summary`}
              back={`/${params.uid}/ship-certificates`} />
          </Form>
        </Formik>

      </div>
    </Page >
  );
}

export default GeneralDeclaration;
