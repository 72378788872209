import React from 'react';
import { Field, ErrorMessage } from "formik";
import TextError from './TextError';
import Checkbox from "../styles/images/checkbox.svg";
import CheckboxChecked from "../styles/images/checkboxChecked.svg";
import "../styles/radio-buttons-custom.scss";

export default function RadioButtons(props) {
  const { label, description, name, options, onOptionClick, ...rest } = props;

  return (
    <div className="form-control radio-buttons-custom">
      <label className="radio-buttons-custom__label">{label}</label>
      {description && (
        <label className="radio-buttons-custom__description">{description}</label>
      )}
      <div className="radio-buttons-custom__container">
        <Field name={name} {...rest}>
          {({ form: { setFieldValue }, field }) => {
            return (
              options.map((option, i) => {
                return (
                  <div
                    onClick={() => {
                      setFieldValue(name, option.value);
                      if (onOptionClick) {
                        onOptionClick(option.value)
                      }
                    }}
                    className={"radio-button-custom" + (field.value === option.value ? " checked" : "")}
                    key={option.key}>
                    {field.value === option.value ? <img src={CheckboxChecked} alt={"CheckboxChecked"} /> : <img src={Checkbox} alt="Checkbox" />}
                    <input
                      type="radio"
                      id={name + `-${i}`}
                      {...field}
                      value={option.value}
                      checked={field.value === option.value} />
                    <label htmlFor={name + `-${i}`}>{option.key}</label>
                  </div>
                )
              })
            )
          }}
        </Field>
      </div>

      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
